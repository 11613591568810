/* You can add global styles to this file, and also import other style files */
@import 'base';

// Default font for the application
body {
  font-family: 'Open Sans', sans-serif;
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="32" width="20" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>');
  background-size: 3rem;
  color: white;
  filter: invert(99%) sepia(7%) saturate(638%) hue-rotate(274deg) brightness(118%) contrast(100%);
  height: 5rem;
  width: 5rem;
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="32" width="20" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>');
  background-size: 3rem;
  color: white;
  filter: invert(99%) sepia(7%) saturate(638%) hue-rotate(274deg) brightness(118%) contrast(100%);
  height: 5rem;
  width: 5rem;
}
