// This is Coding with Kids theme

// Variables for colors
$cwk-red: #CC0000;

// Colors
$red: $cwk-red;

$cwk-main: #e26428;
$theme-action-required-color: white;
$theme-action-required-bg-color: #f7b039;
$theme-action-required-message-bg-color: #ededed;
$theme-button-bg-color: $cwk-main;
$theme-checkout-bg-color: #f8f8f8;
$theme-credit-card-bg-color: #f8f8f8;
$theme-credit-card-default-bg-color: #ededed;
$theme-credit-card-link-color: #3B7AAF;
$theme-error-color: red;
$theme-input-bg-color: white;
$theme-input-border-focus-color: black;
$theme-input-color: grey;
$theme-label-color: grey;
$theme-link-color: #3b7aaf;
$theme-page-bg-color: white;
$theme-row-header-color: bisque;
$theme-row-highlight-color: lighten($theme-row-header-color, 5%);
$theme-sub-menu-bg-color: #c4c4c4;
$theme-switch-bg-color: $cwk-main;
$theme-switch-color: white;
$theme-text-color: #515151;
$theme-text-header-color: $cwk-main;
$theme-text-title-bg-color: #ededed;
$theme-text-title-color: $cwk-main;
$theme-tile-bg-color: #f8f8f8;
$theme-tile-highlight-bg-color: #ededed;

// Variables for fonts
$theme-fonts: "Century Gothic", CenturyGothic, -apple-system, BlinkMacSystemFont, sans-serif;

// Theme classes
.theme-action-required-color {
  color: $theme-action-required-color;
}

.theme-action-required-bg-color {
  color: $theme-action-required-bg-color;
}

.theme-credit-card-bg-color {
  background-color: $theme-credit-card-bg-color;
}

.theme-credit-card-default-bg-color {
  background-color: $theme-credit-card-default-bg-color;
}

.theme-credit-card-link-color {
  color: $theme-credit-card-link-color;
}

.theme-error-color {
  color: $theme-error-color;
}

.theme-input-bg-color {
  background-color: $theme-input-bg-color;
}

.theme-link-color {
  color: $theme-link-color;
}

.theme-page-bg-color {
  background-color: $theme-page-bg-color;
}

.theme-text-color {
  color: $theme-text-color;
}

.theme-text-header-color {
  color: $theme-text-header-color;
}

.theme-text-title-color {
  color: $theme-text-title-color;
}

.theme-tile-bg-color {
  background-color: $theme-tile-bg-color;
}

.theme-tile-highlight-bg-color {
  background-color: $theme-tile-highlight-bg-color;
}
